import { TextField } from "@mui/material";
import colors from "assets/theme/base/colors";

export default function TextAreaComponent({ height, ...props }) {
  return (
    <TextField
      {...props}
      onChange={(event) => {
        props.onChange(event.target.value);
      }}
      fullWidth
      multiline
      sx={{
        background: props.disabled ? colors.disabled.custom : colors.white.main,
        width: {
          xs: "100%",
          sm: "90%",
          md: "80%",
          lg: props.isRegenerate ? "100%" : "550px",
        },

        "& .MuiOutlinedInput-root": {
          height: height || "550px", // Default to 550px if height is not provided
          display: "flex", // Ensure flex layout for proper alignment
          alignItems: "flex-start", // Align text at the top
        },
        "& .MuiInputBase-inputMultiline": {
          margin: 0, // Remove additional margin
          padding: "1rem", // Add uniform padding
        },
        "& .Mui-disabled .MuiInputBase-inputMultiline": {
          "-webkit-text-fill-color": `${colors.secondary[800]} !important`,
        },
        "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      }}
      rows={11}
      value={props.value}
    />
  );
}
