import { useSelector } from "react-redux";
import { useIsThisUser } from "./useCurrentUser";
import { all_roles } from "utils/constants";

export default function useKeywords() {
  const { keywords } = useSelector((state) => state.keywords);

  const { coach, action_plan, coachee, coaching, engagement } = keywords;
  const isSystemAdmin = useIsThisUser(all_roles.systemAdmin);
  return {
    coaches: {
      userType: `Coach`,
      route: `manage-coaches`,
      pageTitle: `Manage ${isSystemAdmin ? "Coach" : coach}`,
      addPageTitle: `Add ${isSystemAdmin ? "Coach" : coach}`,
      sideNavTitle: ` ${coach}`,
      addPageRoute: `add-coach`,
      editPageTitle: `Edit ${isSystemAdmin ? "Coach" : coach}`,
      editPageRoute: `edit-coach`,
      viewPageTitle: `View ${isSystemAdmin ? "Coach" : coach}`,
      viewPageRoute: `view-coach`,
      permissionName: "Coach User Manager",
    },
    coachees: {
      permissionName: "Coachee User Manager",
      userType: `Coachee`,
      route: `manage-coachees`,
      pageTitle: `Manage ${isSystemAdmin ? "Coachee" : coachee}`,
      addPageTitle: `Add ${isSystemAdmin ? "Coachee" : coachee}`,
      sideNavTitle: ` ${coachee}`,
      addPageRoute: `add-coachee`,
      editPageTitle: `Edit ${isSystemAdmin ? "Coachee" : coachee}`,
      editPageRoute: `edit-coachee`,
      viewPageTitle: `View ${isSystemAdmin ? "Coachee" : coachee}`,
      viewPageRoute: `view-coachee`,
    },
    coachingPrograms: {
      userType: `Coaching Program`,
      route: `coaching-programs-console`,
      pageTitle: `${isSystemAdmin ? "Coaching" : coaching} Programs`,
      addPageTitle: `Create ${isSystemAdmin ? "Coaching" : coaching} Program`,
      addPageRoute: `add-coaching-program`,
      editPageTitle: `Edit ${isSystemAdmin ? "Coaching" : coaching} Program`,
      editPageRoute: `edit-coaching-program`,
      viewPageTitle: `View ${isSystemAdmin ? "Coaching" : coaching} Program`,
      viewPageRoute: `view-coaching-program`,
      permissionName: "Coaching Program Management",
    },
    ActionPlanTemplateConstants: {
      permissionName: "Action Plan Template Management",
      userType: `Action Plan Template`,
      route: `action-plan-template`,
      pageTitle: `${isSystemAdmin ? "Action Plan" : action_plan} Templates`,
      addPageTitle: `Create ${
        isSystemAdmin ? "Action Plan" : action_plan
      } Template`,
      addPageRoute: `add-action-plan-template`,
      aiPageRoute: "ai-assist",
      aiGeneratedTemplate: "ai-assist-generated-template",
      regenrateTemplate: "ai-assist-regenerated-template",
      editPageTitle: `Edit ${
        isSystemAdmin ? "Action Plan" : action_plan
      } Template`,
      editPageRoute: `edit-action-plan-template`,
      viewPageTitle: `View ${
        isSystemAdmin ? "Action Plan" : action_plan
      } Template`,
      viewPageRoute: `view-action-plan-template`,
      ADD_HTML_TEXT_TITLE: "Add Html Text",
      ADD_HTML_TEXT_ROUTE: "add-html-text",
      EDIT_EXISTING_ACTION_PLAN_TITLE: `Edit Existing Plan`,
      EDIT_EXISTING_ACTION_PLAN_ROUTE: `edit-existing-action-plan`,
      EXISTING_ACTION_PLANS: `Existing ${
        isSystemAdmin ? "Action Plan" : action_plan
      }`,
      EDIT_EXISTING_ACTION_PLAN_PermissionName: "Action Plan Management",
      INTERACTIVE_TOOL_BUILDER: "Interactive Tool Builder",
      INTERACTIVE_TOOL_BUILDER_ROUTE: "interactive-tool-builder",
      COACHEE_INTERACTIVE_TOOL_BUILDER: "Interactive Tool Builder",
      COACHEE_INTERACTIVE_TOOL_BUILDER_ROUTE:
        "coachee-interactive-tool-builder",
        isAIassist : true,
    },
    engagementConsole: {
      permissionName: "Engagement Management",
      userType: `Engagement Console`,
      route: `engagement-console`,
      pageTitle: `${isSystemAdmin ? "Engagements" : engagement}`,
      addPageTitle: `Create ${isSystemAdmin ? "Engagement" : engagement}`,
      addPageRoute: `create-engagement`,
      editPageTitle: `Edit ${isSystemAdmin ? "Engagement" : engagement}`,
      editPageRoute: `edit-engagement`,
      viewPageTitle: `View ${isSystemAdmin ? "Engagement" : engagement}`,
      viewPageRoute: `view-engagement`,
      viewSessionListTitle: `View Session List`,
      viewSessionListRoute: `view-session-list`,
      viewReportTitle: `View Progress Report`,
      viewReportRoute: `view-report`,
      EEDIT_COACHEE_PROGRESS_TITLE: `Collaborative View`,
      EDIT_COACHEE_PROGRESS_ROUTE: "update-coachee-progress",
      VIEW_INDIVIUAL_PROGRESS_TITLE: "Individual Progress",
      VIEW_INDIVIUAL_PROGRESS_ROUTE: "individual-progress",
    },
    coacheeDashboard: {
      userType: "Coachee Dashboard",
      route: "coachee-dashboard",
      pageTitle: `${coachee} Dashboard`,
      viewReportTitle: "View Report",
      viewReportRoute: "view-report",
      permissionName: "Rung Jumper Dashboard",
    },
  };
}
