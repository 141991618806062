import { FormControl } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useLocation, useNavigate } from "react-router-dom";

import colors from "assets/theme/base/colors";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useDispatch } from "react-redux";
// import { setSearchQuery } from "redux-store/features/dashboard/dataTableSlice";
import { setDialogBox, useMaterialUIController } from "context";
import { ButtonIcon } from "./IconifyIcon";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { setSubActivePanel } from "redux-store/features/dashboard/coacheeTabsSlice";
import { setActivePanel } from "redux-store/features/dashboard/coacheeTabsSlice";
import { all_roles } from "utils/constants";
import { useIsThisUser } from "custom-hooks/useCurrentUser";
import { setInteractiveToolsList } from "redux-store/features/dashboard/coacheeTabsSlice";

const PageHeader = ({
  title,
  canSearch = true,
  childPage = null,
  parentRoute = null,
  onactivePanel = null,
  clickHandler = null,
  confirmCancel = false,
  cancelDialogDescription = "All your entered information will be lost",
  exportButton = null,
  setSearchQuery = null,
  setIsSearchQueryChanged = null,
  isHtmlEditor = null,
  engagement_type = null,
  group_id = null,
  engagementData = null,
  route = null,
  isAiAssist,
}) => {
  const { authorValuesChanged } = useSelector((state) => state.coacheeTabs);

  const location = useLocation();
  const redirectedFrom = location?.state?.redirectedFrom;
  const activeTab = location?.state?.activePanel;
  const { subActivePanel, activePanel } = useSelector(
    (state) => state.coacheeTabs
  );
  const dispatchAction = useDispatch();
  const navigate = useNavigate();
  const [, dispatch] = useMaterialUIController();
  const { grey } = colors;

  const debounce = (cb, delay = 1000) => {
    let timeout;
    return (args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(args);
      }, delay);
    };
  };

  const handleSearchQueryChange = debounce(({ target: { value } }) => {
    setIsSearchQueryChanged(true);

    setSearchQuery(value);
  });
  const confirmCancelHandler = () => {
    setDialogBox(dispatch, {
      open: true,
      title: "Do you wish to proceed?",
      body: (
        <MDTypography
          variant="h4"
          color="secondary"
          fontWeight="regular"
          textGradient
          m={2}
          sx={{ textAlign: "center", marginLeft: 12 }}
        >
          {cancelDialogDescription}
        </MDTypography>
      ),

      primaryBtnText: "YES",
      primaryAction: () => {
        setDialogBox(dispatch, {
          open: false,
          title: "",
          body: "",
        });
        if (isHtmlEditor) {
          navigate(parentRoute, {
            state: isHtmlEditor,
          });
        } else {
          navigate(
            redirectedFrom
              ? redirectedFrom
              : parentRoute !== "/coachee-dashboard"
              ? parentRoute
              : "/",
            {
              state: {
                coachee_action_plan_id:
                  location?.state?.coachee_action_plan_id || null,
                engagement_type: engagement_type || null,
                group_id: group_id || null,
                isSelectedCoachee: engagement_type || group_id ? true : null,
                engagementData: engagementData || null,
                pathname: route || null,
              },
            }
          );
          dispatchAction(setInteractiveToolsList(null));
        }
      },
      cancelTxt: "NO",
    });
  };

  const isCoachee = useIsThisUser(all_roles.coachee);

  return (
    <MDBox
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      ml={2}
      sx={{ marginBottom: "14px !important" }}
    >
      <MDBox>
        {(childPage || parentRoute) && (
          <MDTypography
            variant="h2"
            // color="primary"
            fontWeight="bold"
            textGradient
            onClick={() => {
              if (activeTab) {
                navigate(parentRoute, {
                  state: {
                    activeTab: activeTab,
                    coachee_action_plan_id:
                      location?.state?.coachee_action_plan_id || null,
                    isActionPlanSelected: true,
                    engagement_id: location?.state?.engagement_id || null,
                  },
                });

                return;
              }
              if (subActivePanel) {
                if (activePanel === 4) {
                  dispatchAction(setSubActivePanel(false));
                  return;
                } else {
                  dispatchAction(setActivePanel(4));
                  return;
                }
              }
              if (clickHandler) {
                clickHandler();
              } else if (confirmCancel) {
                confirmCancelHandler();
              } else {
                if (authorValuesChanged && !engagementData) {
                  confirmCancelHandler();
                } else {
                  dispatchAction(setActivePanel(1));
                  navigate(
                    redirectedFrom
                      ? redirectedFrom
                      : parentRoute !== "/coachee-dashboard"
                      ? parentRoute
                      : "/",
                    {
                      state: {
                        coachee_action_plan_id:
                          location?.state?.coachee_action_plan_id || null,
                        engagement_type: engagement_type || null,
                        group_id: group_id || null,
                        isSelectedCoachee:
                          engagement_type || group_id ? true : null,
                        engagementData: engagementData || null,
                        pathname: route || null,
                      },
                    }
                  );
                }
              }
              dispatchAction(setInteractiveToolsList(null));
            }}
            sx={{
              cursor: "pointer",
              color: isCoachee
                ? colors.coacheePortal.primary
                : colors.primary.main,
            }}
          >
            {isAiAssist ? null : <ChevronLeftIcon sx={{ ml: -5, mb: -1 }} />}
          </MDTypography>
        )}
        <MDTypography
          component="span"
          variant="h4"
          // color="primary"
          sx={{
            lineHeight: 0,
            color: isCoachee
              ? colors.coacheePortal.primary
              : colors.primary.main,
          }}
          textTransform="capitalize"
        >
          {title}
          {childPage && (
            <>
              <MDTypography
                component="span"
                variant="h2"
                color="secondary"
                fontWeight="light"
                sx={{ lineHeight: 0, marginLeft: 1 }}
                textTransform="capitalize"
              >
                |
              </MDTypography>
              <MDTypography
                component="span"
                variant="h6"
                sx={{ lineHeight: 1, marginLeft: 1, color: grey[700] }}
                textTransform="capitalize"
              >
                {childPage}
              </MDTypography>
            </>
          )}
        </MDTypography>
      </MDBox>
      {exportButton && exportButton}
      {canSearch && (
        <MDBox>
          <FormControl
            sx={{
              m: 1,
              width: "25ch",
              background: isCoachee
                ? colors.coacheePortal.backgroundColor
                : colors.background.primary,
            }}
            variant="outlined"
          >
            <InputLabel htmlFor="search">
              <span
                sx={{
                  color: isCoachee
                    ? colors.coacheePortal.primary
                    : colors.primary.light,
                }}
              >
                Search here...
              </span>
            </InputLabel>
            <OutlinedInput
              id="search"
              type="search"
              label="Search here..."
              // value={searchValue}
              onChange={handleSearchQueryChange}
              endAdornment={
                <InputAdornment position="end">
                  <ButtonIcon
                    icon={"eva:search-fill"}
                    width={23}
                    height={23}
                    iconStyle={{
                      // color: colors.primary.main
                      color: isCoachee
                        ? colors.coacheePortal.primary
                        : colors.primary.main,
                    }}
                  />
                </InputAdornment>
              }
            />
          </FormControl>
        </MDBox>
      )}
    </MDBox>
  );
};

export default PageHeader;
