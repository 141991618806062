import React, { useState } from "react";
import {
  Card,
  CardContent,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Icon,
} from "@mui/material";
import colors from "assets/theme/base/colors";
import MDButton from "components/MDButton";
import YesNoIcon from "../../../../assets/images/yes-no.png";
import MDBox from "components/MDBox";
import { ai_assist_feedback_records } from "utils/apiMethods";

const TemplateFeedbackQuestions = ({ templateFeedbackQuestions }) => {
  const [questions, setQuestions] = useState(templateFeedbackQuestions);
  const [newQuestion, setNewQuestion] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [editingId, setEditingId] = useState(null);
  const [editingText, setEditingText] = useState("");

  const handleAddQuestion = async () => {
    if (!newQuestion || !questionType) {
      alert("Please provide a question and select a type.");
      return;
    }

    const payload = {
      question: newQuestion,
      question_type:
        questionType === "star" ? 1 : questionType === "yesno" ? 2 : 3,
      feedBack_type: 2,
    };

    try {
      const response = await ai_assist_feedback_records.addFeedbackQuestions(
        payload
      );
      setQuestions([
        ...questions,
        {
          feedback_question_id: response.data.id,
          question: newQuestion,
          question_type: payload.question_type,
          feedBack_type: 2,
        },
      ]);
      resetInputs();
    } catch (error) {
      console.error("Error adding new question:", error);
    }
  };

  const resetInputs = () => {
    setNewQuestion("");
    setQuestionType("");
  };

  const handleEditQuestion = (id, text) => {
    setEditingId(id);
    setEditingText(text);
  };

  const handleSaveEdit = async () => {
    const payload = {
      question: editingText,
      question_type:
        questionType === "star" ? 1 : questionType === "yesno" ? 2 : 3,
      feedBack_type: 1,
    };

    try {
      await ai_assist_feedback_records.editFeedbackQuestions(
        editingId,
        payload
      );
      const updatedQuestions = questions.map((q) =>
        q.feedback_question_id === editingId
          ? { ...q, question: editingText }
          : q
      );
      setQuestions(updatedQuestions);
      setEditingId(null);
      setEditingText("");
    } catch (error) {
      console.error("Error saving edited question:", error);
    }
  };

  const handleDeleteQuestion = async (id) => {
    if (window.confirm("Are you sure you want to delete this question?")) {
      try {
        await ai_assist_feedback_records.deleteFeedbackQuestion(id);
        const updatedQuestions = questions.filter(
          (q) => q.feedback_question_id !== id
        );
        setQuestions(updatedQuestions);
      } catch (error) {
        console.error("Error deleting question:", error);
      }
    }
  };

  const renderQuestionType = (type) => {
    if (type === 1) {
      return <Icon sx={{ color: "gold" }}>star</Icon>;
    } else if (type === 2) {
      return (
        <img
          src={YesNoIcon}
          alt="Yes/No Icon"
          style={{ width: "24px", height: "24px" }}
        />
      );
    } else if (type === 3) {
      return <Icon sx={{ marginRight: "8px", color: "primary" }}>comment</Icon>;
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: colors.background.secondary,
        color: "white",
        borderRadius: "16px",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontSize: "17px",
          color: colors.text.main,
          fontFamily: '"Poppins", sans-serif',
          paddingTop: "20px",
          paddingLeft: "20px",
        }}
      >
        Template Feedback Questions
      </Typography>
      <CardContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={3}
            mt={3}
          >
            <TextField
              label="New Question"
              variant="outlined"
              fullWidth
              value={newQuestion}
              onChange={(e) => setNewQuestion(e.target.value)}
              sx={{
                backgroundColor: colors.white.focus,
                borderRadius: "8px",
                width: "700px",
              }}
            />
            <FormControl
              variant="outlined"
              sx={{ marginLeft: "16px", flex: 1 }}
            >
              <Select
                labelId="question-type-label"
                value={questionType}
                onChange={(e) => setQuestionType(e.target.value)}
                label="Select Question Type *"
                displayEmpty
              >
                <MenuItem disabled value="">
                  <em>
                    Select Question Type <span style={{ color: "red" }}>*</span>
                  </em>
                </MenuItem>
                <MenuItem value="star">
                  <MDBox display="flex" alignItems="center">
                    <Icon sx={{ color: "gold", marginRight: "8px" }}>star</Icon>
                    Star Rating
                  </MDBox>
                </MenuItem>
                <MenuItem value="yesno">
                  <MDBox display="flex" alignItems="center">
                    <img
                      src={YesNoIcon}
                      alt="Yes/No"
                      style={{
                        width: "24px",
                        height: "24px",
                        marginRight: "8px",
                      }}
                    />
                    Yes/No
                  </MDBox>
                </MenuItem>
                {/* <MenuItem value="comment">
                  <MDBox display="flex" alignItems="center">
                    <Icon sx={{ marginRight: "8px" }}>comment</Icon>
                    Comment
                  </MDBox>
                </MenuItem> */}
              </Select>
            </FormControl>
            <MDButton
              onClick={handleAddQuestion}
              color="primary"
              style={{ marginLeft: "16px", height: "50px" }}
            >
              Add Question
            </MDButton>
          </MDBox>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Question</TableCell>
                  <TableCell align="center">Type</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {questions.map((question) => (
                  <TableRow key={question.feedback_question_id}>
                    <TableCell>
                      {editingId === question.feedback_question_id ? (
                        <input
                          value={editingText}
                          onChange={(e) => setEditingText(e.target.value)}
                          style={{
                            height: "30px",
                            width: "100%",
                            border: "none",
                          }}
                        ></input>
                      ) : (
                        <span style={{ color: "black" }}>
                          {question.question}
                        </span>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {renderQuestionType(question.question_type)}
                    </TableCell>
                    <TableCell align="center">
                      {editingId === question.feedback_question_id ? (
                        <MDButton onClick={handleSaveEdit} color="success">
                          Save
                        </MDButton>
                      ) : (
                        <Icon
                          onClick={() =>
                            handleEditQuestion(
                              question.feedback_question_id,
                              question.question
                            )
                          }
                          sx={{ cursor: "pointer", color: "info.main" }}
                        >
                          edit
                        </Icon>
                      )}
                      <Icon
                        onClick={() =>
                          handleDeleteQuestion(question.feedback_question_id)
                        }
                        sx={{
                          cursor: "pointer",
                          color: "error.main",
                          marginLeft: "16px",
                        }}
                      >
                        delete
                      </Icon>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </form>
      </CardContent>
    </Card>
  );
};

export default TemplateFeedbackQuestions;
