import { useEffect } from "react";

// react-router components
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

import useRoutes from "utils/routes";
import ProtectedRoute from "layouts/routing/ProtectedRoute";
import PublicRoute from "layouts/routing/PublicRoute";
import { api } from "utils/axios-config";
import { useIs_CurrentPath_a_PublicRoute } from "utils/helper-method";
import MDSnackbar from "components/MDSnackbar";
import { hideSnackbar } from "redux-store/features/dashboard/snackbarSlice";
import { store } from "redux-store/store";
import { Backdrop, CircularProgress } from "@mui/material";
import { useMaterialUIController } from "context";
import DialogBox from "components/custom-components/dialog-box";
import { Slide, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  isChildRouteAllowed,
  logoutAfterTokenExpiry,
  isThisRouteAllowed,
} from "utils/helper-method";
import { ActionPlanTemplateConstants } from "utils/constants";
import {
  setInteractiveToolsList,
  setAuthorValuesChanged,
} from "redux-store/features/dashboard/coacheeTabsSlice";

export default function App() {
  const { modulesPermissions } = useSelector(
    (state) => state.Roles_Permissions
  );
  let routes = useRoutes();
  const dispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const { dialogBox } = controller;
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isPublicRoute = useIs_CurrentPath_a_PublicRoute(pathname);

  const { isOpen, type, title, content, icon, time } = useSelector(
    (state) => state.snackbar
  );

  const { isCustomerOrganizationsLoading } = useSelector(
    (state) => state.customers
  );
  const { isCoachProgramsLoading } = useSelector(
    (state) => state.engagementConsole
  );
  const { loading } = useSelector((state) => state.dataTable);
  const { isGroupCoacheesLoading } = useSelector((state) => state.groups);
  const { isLoading } = useSelector((state) => state.loader);
  const { isKeywordsLoading } = useSelector((state) => state.keywords);
  const { isSponsoringSponsorLoading } = useSelector(
    (state) => state.sponsoringEntities
  );
  const { authorValuesChanged } = useSelector((state) => state.coacheeTabs);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        dispatch(hideSnackbar());
      }, time);
    }
  }, [isOpen]);
  // const [userRolePermissions, setUserRolePermissions] = useState([]);
  const toggleSnackbar = () => {
    dispatch(hideSnackbar());
  };
  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    dispatch(setInteractiveToolsList(null));
    if (authorValuesChanged) {
      dispatch(setAuthorValuesChanged(false));
    }
  }, [pathname]);

  const getChildRoutes = (route) => {
    return route.children.map((childRoute) => {
      let isRouteAllowed = isChildRouteAllowed(
        route.permissionName,
        modulesPermissions,
        childRoute.type,
        childRoute.permissionName
      );
      if (isRouteAllowed) {
        return (
          <Route
            exact
            path={childRoute.route}
            element={childRoute.component}
            key={childRoute.key}
          />
        );
      }
    });
  };

  const getCollapseRoutes = (routes) => {
    return routes.map((route) => {
      if (route.collapse) {
        return getCollapseRoutes(route.collapse);
      }
      if (route.route) {
        let isRouteAllowed = isThisRouteAllowed(route, modulesPermissions);
        if (isRouteAllowed) {
          return (
            <>
              <Route
                exact
                path={route.route}
                element={route.component}
                key={route.key}
              />
              {route.children && getChildRoutes(route)}
            </>
          );
        }
      }
      return null;
    });
  };

  const getRoutes = ({ protectedRoutes, publicRoutes }) => {
    return (
      <>
        <Route element={<PublicRoute />}>
          {publicRoutes.map((route) => {
            return (
              <Route
                path={route.route}
                element={route.component}
                key={route.key}
              />
            );
          })}
        </Route>
        <Route element={<ProtectedRoute />}>
          {protectedRoutes.map((route) => {
            if (route.collapse) {
              return getCollapseRoutes(route.collapse);
            } else {
              let isRouteAllowed = isThisRouteAllowed(
                route,
                modulesPermissions
              );

              if (
                isRouteAllowed ||
                route.key ===
                  `${ActionPlanTemplateConstants.route}/:coachee_action_plan_id/${ActionPlanTemplateConstants.COACHEE_INTERACTIVE_TOOL_BUILDER_ROUTE}/:resource_id`
              ) {
                return (
                  <>
                    <Route
                      exact
                      path={route.route}
                      element={route.component}
                      key={route.key}
                    />
                    {route.children && getChildRoutes(route)}
                  </>
                );
              }
              return null;
            }
          })}
        </Route>
        ;
      </>
    );
  };
  // axios request interceptor config. it will execute before making api calls
  api.interceptors.request.use(
    async (config) => {
      // destructuring store internally so we can get the updated values
      const authObj = store?.getState()?.auth;

      const stateToken = authObj?.token;
      const tokenExpiry = authObj?.expiry;

      // if token Expiry is greater than current date so hit api or sign out to the app
      if (
        (!isPublicRoute ||
          config.url === "keyword_substitution/list/" ||
          config.url === "/roles_and_rights/user_modules_by_user") &&
        stateToken
      ) {
        // current path is not a public route, only then add the following Authorization header in the api request's
        if (new Date(tokenExpiry) > new Date()) {
          config.headers.Authorization = `Bearer ${stateToken}`;
        }
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  api.interceptors.response.use(
    (response) => response,
    logoutAfterTokenExpiry(dispatch, navigate, pathname)
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/sign-in" />} />
      </Routes>
      <MDSnackbar
        color={type}
        icon={icon}
        title={title}
        content={content}
        open={isOpen}
        close={toggleSnackbar}
      />
      {/* {(isLoading ||
        loading ||
        isGroupCoacheesLoading ||
        isCustomerOrganizationsLoading ||
        isCoachProgramsLoading ||
        isSponsoringSponsorLoading ||
        isKeywordsLoading) &&
        !isPublicRoute && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" size={100} />
          </Backdrop>
        )} */}
      {dialogBox?.open && <DialogBox />}
      <ToastContainer
        position={toast.POSITION.BOTTOM_RIGHT}
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="colored"
        transition={Slide}
        style={{
          fontSize: "1rem",
          minWidth: "25rem",
        }}
      />
    </ThemeProvider>
  );
}
