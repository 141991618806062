

// Enum for FeedbackType
export const FeedbackType = Object.freeze({
    Keyword: 1,
    Template: 2,
  });
  
  // Enum for ImportanceLevel
  export const ImportanceLevel = Object.freeze({
    LeastImportant: 1,
    LessImportant: 2,
    Important: 3,
    MoreImportant: 4,
    ExtremelyImportant: 5,
  });
  
  // Enum for NoteType
  export const NoteType = Object.freeze({
    AI_Assist: 1,
    AI_RegenerateTemplate: 2,
    AI_RegenerateObjective: 3,
    AI_Finalize: 4,
    AI_Instruction: 3, // Duplicate value of 3 as specified
  });
  
  // Enum for QuestionType
  export const QuestionType = Object.freeze({
    Scale: 1,
    TrueFalse: 2,
  });
  