import React, { useEffect, useState } from "react";
import { Card, Button, TextField, Typography, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import PageHeader from "components/custom-components/page-header";
import MDBox from "components/MDBox";
import colors from "assets/theme/base/colors";
import { ai_system_notes } from "utils/apiMethods";

const Notes = () => {
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState({ notes_type: "", notes: "" });
  const [editingNote, setEditingNote] = useState(null);
  const [isAddingNote, setIsAddingNote] = useState(false);

  const getNoteTitle = (notes_type) => {
    switch (notes_type) {
      case 1:
        return "AI Assist Note";
      case 2:
        return "AI Regenerate Template";
      case 3:
        return "AI Regenerate Objective";
      case 4:
        return "Finalize and Save";
      case 5:
        return "Instructions:";
      case 6:
        return "Template Regenerate Limit";
      case 7:
        return "Review Keywords";
      default:
        return "Unknown Note Type";
    }
  };

  const resetForm = () => {
    setNewNote({ notes_type: "", notes: "" });
    setEditingNote(null);
    setIsAddingNote(false);
  };

  const handleAddNote = () => {
    if (newNote.notes_type && newNote.notes) {
      setNotes([...notes, { ...newNote, id: notes.length + 1 }]);
      resetForm();
    }
  };

  const handleEditNote = (note) => {
    setNewNote(note);
    setEditingNote(note);
    setIsAddingNote(true);
  };

  const handleUpdateNote = async () => {
    try {
      if (editingNote) {
        const updatedNote = {
          notes_type: newNote.notes_type,
          notes: newNote.notes,
        };

        await ai_system_notes.editSystemNote(updatedNote);
        window.location.reload();
      }
    } catch (error) {
      console.error("Error updating note:", error);
    }
  };

  const getAllNotes = async () => {
    try {
      const response = await ai_system_notes.getAllSystemNotes();
      setNotes(response.data);
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  };

  const onBack = () => {
    resetForm();
  };

  useEffect(() => {
    getAllNotes();
  }, []);

  return (
    <MDBox my={3}>
      <PageHeader title="Manage Notes for AI Assist" canSearch={false} />

      <Card
        sx={{
          backgroundColor: colors.background.secondary,
          borderRadius: "16px",
          padding: "16px",
          margin: "30px auto 0",
          width: { xs: "30%", sm: "40%", md: "60%", lg: "90%" },
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: "17px",
            color: colors.text.main,
            fontFamily: '"Poppins", sans-serif',
          }}
        >
          {!isAddingNote ? "  AI-Assist - Notes" : ""}
        </Typography>

        {isAddingNote && (
          <>
            {/* Back Button placed above the card */}
            <IconButton
              onClick={onBack}
              sx={{
                position: "absolute",
                top: "20px",
                left: "20px",
                display: "flex",
                alignItems: "center",
                backgroundColor: colors.background.secondary,
                borderRadius: "50%",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              }}
            >
              <ArrowBackIcon color="primary" />
            </IconButton>

            <Card
              sx={{
                backgroundColor: colors.white.focus,
                borderRadius: "16px",
                padding: "16px",
                marginTop: "50px",
              }}
            >
              <Typography variant="h5" gutterBottom>
                Edit Note
              </Typography>
              <TextField
                label="Content"
                variant="outlined"
                value={newNote.notes}
                onChange={(e) =>
                  setNewNote({ ...newNote, notes: e.target.value })
                }
                fullWidth
                multiline
                rows={4}
                margin="normal"
              />
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "primary.main",
                  color: colors.white.focus,
                  "&:hover": {
                    backgroundColor: "primary.dark",
                  },
                }}
                onClick={handleUpdateNote}
              >
                Update Note
              </Button>
            </Card>
          </>
        )}

        {!isAddingNote &&
          notes.map((note) => (
            <Card
              key={note.id}
              sx={{
                marginTop: "16px",
                padding: "16px",
                backgroundColor: colors.white.focus,
                position: "relative",
              }}
            >
              <Typography variant="h6">
                {getNoteTitle(note.notes_type)}
              </Typography>
              <Typography variant="body2">{note.notes}</Typography>
              <div
                style={{
                  position: "absolute",
                  bottom: "16px",
                  right: "16px",
                  display: "flex",
                  gap: "8px",
                }}
              >
                <IconButton
                  onClick={() => handleEditNote(note)}
                  sx={{ color: "info.main" }}
                >
                  <EditIcon />
                </IconButton>
              </div>
            </Card>
          ))}
      </Card>
    </MDBox>
  );
};

export default Notes;
