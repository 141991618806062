import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import PageHeader from "components/custom-components/page-header";
import PrimaryButton from "components/custom-components/primary-button";
import {
  getCustomerOrganizationsForForms,
  setSelectedCustomerForForms,
} from "redux-store/features/dashboard/customersSlice";
import colors from "assets/theme/base/colors";
import useKeywords from "custom-hooks/useKeywords";
import TextAreaComponent from "components/custom-components/text-area";
import { use_ai_assist } from "utils/apiMethods";
import { TextField } from "@mui/material";

export default function RegenrateActionPlanTemplateForm({
  snackbar,
  isLoading,
  toggleSnackbar,
  onSubmit,
  primaryButton,
  secondaryButton,
  state,
  handleIsActive,
  isActive,
  disabled = false,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { payload } = location.state || {};

  const { ActionPlanTemplateConstants } = useKeywords();

  const parentRoute = `/${ActionPlanTemplateConstants.route}`;
  const aiAssistRounte = ActionPlanTemplateConstants.aiPageRoute;
  const { customerDropdownForForms } = useSelector((state) => state.customers);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isCOLoading, setIsCOLoading] = useState(false);
  const [isTitleEdited, setIsTitleEdited] = useState(false);
  const [title, setTitle] = useState(payload?.title || "");
  const [refineId, setRefineId] = useState([]);
  const [originalDescription, setOriginalDescription] = useState(
    payload?.description || ""
  );
  const [additionalDescription, setAdditionalDescription] = useState("");
  const isRefine = payload.isRefine;
  const isRegenerate = payload.isRegenerate;
  const templateId = useParams();
  const id = templateId.action_plan_template_id;
  const [errorMessage, setErrorMessage] = useState("");
 

  useEffect(() => {
    if (customerDropdownForForms.length) {
      const dropdownValues = customerDropdownForForms.map((customer) => ({
        text: customer.customer_name,
        value: customer.customer_id,
      }));

      setIsCOLoading(false);
    }
  }, [customerDropdownForForms]);

  const handleNextClick = async () => {
    setIsCOLoading(true); // Start loading spinner
    if (additionalDescription.length > 800) {
      setErrorMessage("Additional description must not exceed 800 characters."); // Set error message
      setIsCOLoading(false);
      return; // Prevent navigation
    }
    const combinedDescription = `${originalDescription}\n\n${additionalDescription}`;
    setErrorMessage("");
    const body = {
      title: payload.title,
      description: combinedDescription,
      customer_id: payload.customer_id,
    };

    try {
      const response = await use_ai_assist.aiRegenerateTemplate(id, body);
      const returnedId = response.template_meta_data_id;
      const payload = { isRegenerate: true };
      setTimeout(() => {
        setIsCOLoading(false); // Stop loading spinner
        navigate(`${parentRoute}/${aiAssistRounte}/${returnedId}`, {
          state: { payload },
        });
      }, 1000); // Adjust delay as needed
    } catch (error) {
      setIsCOLoading(false); // Stop loading spinner on error
      console.error("Error while regenerating template:", error);
    }
  };

  const handleNextClickRefine = async () => {
    setIsCOLoading(true); // Start loading spinner
    if (additionalDescription.length > 800) {
      setErrorMessage("Additional description must not exceed 800 characters."); // Set error message
      setIsCOLoading(false);
      return; // Prevent navigation
    }

    const combinedDescription = `${originalDescription}\n\n${additionalDescription}`;
    setErrorMessage("");
    const body = {
      title: title,
      description: combinedDescription,
      customer_id: payload.customer_id,
    };

    try {
      const response = await use_ai_assist.useAiAssist(body);
      const returnedId = response.data.template_meta_data_id;
      setRefineId(returnedId); // Set the returned ID to refine state

      const payload = isRefine ? { isRefine: true } : { isRegenerate: true };

      setTimeout(() => {
        setIsCOLoading(false);
        navigate(`${parentRoute}/${aiAssistRounte}/${returnedId}`, {
          state: { payload },
        });
      }, 1000);
    } catch (error) {
      setIsCOLoading(false);
      console.error("Error while regenerating template:", error);
    }
  };
  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    if (value.length > 2000) {
      setErrorMessage("Description must not exceed 2000 characters.");
    } else {
      setErrorMessage("");
    }
    setOriginalDescription(value);
  };
  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      sx={{ height: "100%" }}
    >
      <Grid item xs={12} lg={11} sx={{ maxHeight: "100vh !important" }}>
        <form>
          <MDBox
            display="flex"
            justifyContent="space-between"
            style={{ marginTop: "30px", marginBottom: "20px" }}
          >
            <MDBox>
              <PageHeader
                title={ActionPlanTemplateConstants?.pageTitle}
                canSearch={false}
                childPage={
                  isRefine
                    ? "Refine Template with AI-Assit"
                    : "Re-generate Template with AI-Assist"
                }
                parentRoute={state?.parentRoute}
                confirmCancel={true}
                cancelDialogDescription="All your entered information will be lost."
                isAiAssist={true}
              />
            </MDBox>
            <MDBox>
              <PrimaryButton
                text="&nbsp;&nbsp;&nbsp;Next&nbsp;&nbsp;&nbsp;"
                type="button"
                onClick={isRefine ? handleNextClickRefine : handleNextClick}
                disabled={
                  isLoading ||
                  isCOLoading ||
                  (isRefine && (!isTitleEdited || title === payload.title)) ||
                  originalDescription.length > 2000
                }
              />
            </MDBox>
          </MDBox>

          <Card
            sx={{
              height: "100%",
              backgroundColor: colors.background.secondary,
              maxHeight: "95vh",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              marginBottom: "30px",
            }}
          >
            <MDBox
              sx={{
                padding: "15px",
                backgroundColor: "background.paper",
                borderRadius: "8px",
                flexShrink: 0,
              }}
            >
              <MDBox
                component="label"
                sx={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                  color: "text.primary",
                }}
              >
                Template Title:
              </MDBox>
              {isRefine ? (
                <TextField
                  variant="outlined"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                    setIsTitleEdited(true);
                  }}
                  fullWidth
                  margin="normal"
                  placeholder="Enter new title"
                  sx={{
                    mt: 1,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "primary.main",
                      },
                      "&:hover fieldset": {
                        borderColor: "primary.dark",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "primary.dark",
                      },
                    },
                  }}
                />
              ) : (
                <MDBox
                  sx={{ mt: 1, color: "text.secondary", fontSize: "1rem" }}
                >
                  {payload?.title || "Default Title"}
                </MDBox>
              )}
            </MDBox>

            <MDBox
              display="flex"
              flexDirection="column"
              gap={2}
              padding={2}
              sx={{
                overflowY: "auto", // Allow scrolling only for this container
                flexGrow: 1, // Occupy remaining space
              }}
            >
              {/* Original Description Text Area */}
              <MDBox padding={2}>
                <label
                  style={{
                    color: colors.primary.main,
                    marginBottom: "8px",
                    display: "block",
                  }}
                >
                  Description
                </label>
                <TextField
                  value={originalDescription}
                  onChange={handleDescriptionChange}
                  fullWidth
                  multiline
                  rows={10}
                  error={!!errorMessage}
                  helperText={errorMessage}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: errorMessage
                          ? colors.error.main
                          : colors.primary.main,
                      },
                    },
                  }}
                />
              </MDBox>
            </MDBox>
          </Card>
        </form>
      </Grid>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoading || isCOLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDSnackbar
        color="success"
        icon="check"
        title="Success"
        content="Your Template has been created successfully."
        open={snackbar}
        onClose={() => toggleSnackbar(false)}
        close={() => toggleSnackbar(false)}
        bgWhite
      />
    </Grid>
  );
}
