import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { useRef } from "react";
import colors from "assets/theme/base/colors";
import { useIsThisUser } from "custom-hooks/useCurrentUser";
import { all_roles } from "utils/constants";

export default function PrimaryButton({
  text,
  icon,
  onClick = null,
  type = null,
  onFileChange = null,
  accept = null,
  disabled
}) {
  const fileInput = useRef();
  const isCoachee = useIsThisUser(all_roles.coachee);
  return type === "file" ? (
    <>
      <MDButton
        variant="contained"
        // color="primary"
        circular
        size="large"
        sx={{ marginLeft: 3, textTransform: "capitalize" }}
        style={{
          color: colors.white.main,
          backgroundColor: isCoachee
            ? colors.coacheePortal.primary
            : colors.primary.main
        }}
        onClick={() => fileInput.current.click()}
        disabled={disabled}
      >
        {icon && <Icon>{icon}</Icon>}
        {text}
      </MDButton>

      <input
        ref={fileInput}
        type="file"
        style={{ display: "none" }}
        onChange={onFileChange}
        accept={accept}
      />
    </>
  ) : (
    <MDButton
      variant="contained"
      // color="primary"
      circular
      size="large"
      sx={{
        marginLeft: 3,
        textTransform: "capitalize",
        alignItem: "center",
        textAlign: "center"
      }}
      style={{
        color: colors.white.main,
        backgroundColor: isCoachee
          ? colors.coacheePortal.primary
          : colors.primary.main
      }}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {icon && <Icon sx={{ marginLeft: -0.6 }}>{icon}</Icon>}
      {text}
    </MDButton>
  );
}
