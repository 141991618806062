import colors from "assets/theme/base/colors";
import { exportCSV, exportPDF } from "custom-hooks/exportData";

// #region api URLs & endpoints
export const URLs = {
  mock_baseUrl: "https://38162527-6785-4f92-9afb-435a828d02b4.mock.pstmn.io",
  mock_baseUrl_alt:
    "https://27641259-0748-4a2d-b1b8-65f5444dfc20.mock.pstmn.io",
  BASE_URL_ALPHA:
    "https://rungjumperappv2-rungjumper-backend-dev.azurewebsites.net/v1/",
  BASE_URL_BETA:
    "https://rungjumperappv2-rungjumper-backend-release.azurewebsites.net/v1/",
  BASE_URL_PROD: "https://rungjumperappv2.azurewebsites.net/v1/",
  loginUrl: `auth/login`,
  forgotPasswordUrl: `auth/forgot_password`,
  verifyTokenUrl: "auth/verify_forgot_password_token",
  resetPasswordUrl: "auth/reset_password",
  REFRESH_AUTH_TOKEN_URL: "auth/refresh_token/",
  SESSION_FEEDBACK_REMINDER: "feed_back/feedback_reminder",
  //profile
  updateUserProfile: "/users/me",
  changePassword: "users/me/change_password",

  // dashboard
  getUserRolePermissions: "roles_and_rights/user_claims_by_role/",
  getUserRolePermissionsAndModules: "/roles_and_rights/user_modules_by_user",
  getUserRolePermissionsAndModulesbyRole:
    "/roles_and_rights/user_modules_by_role/",
  GET_CUSTOMER_ROLE_RIGHTS: "/customers/customer_claims_by_role/",

  //AI Assist
  use_ai_assist_template_generation:
    "/action_plan_template_keywords/description",
  use_ai_generated_keywords: "/action_plan_template_keywords",
  regenerate_keywords: "/action_plan_template_keywords/update",
  update_ai_generated_keywords: "action_plan_template_keywords/store_keywords",
  store_parameters: "/action_plan_template_keywords/store_parameters",
  get_ai_generated_template: "/ai_action_plan_template",
  save_ai_generated_template: "/ai_action_plan_template/save",
  ai_assist_regenearte_template: "/ai_action_plan_template/regenerate_template",
  ai_regenerate_objective:
    "/ai_action_plan_template_objective/re_generate_objective",
  getAll_system_notes: "/system_notes",
  post_system_note: "/system_notes",
  post_keyword_categories: "/keyword_categories",
  get_keyword_categories: "/keyword_categories",
  edit_keyords_category: "/keyword_categories",
  delete_keyword_category: "/keyword_categories",
  get_feedback_question: "/feedback_question",
  add_feedback_question: "/feedback_question",
  edit_feedback_question: "/feedback_question",
  delete_feedback_question: "/feedback_question",
  getAll_AI_Assist_Feedback_Records: "/feedback_response",
  submit_feedback: "/feedback_response",

  //ai_template_operations
  createAiObjective: "ai_action_plan_template_objective",
  updateAiObjective: "ai_action_plan_template_objective/",
  deleteAiObjective: "ai_action_plan_template_objective/",

  createAiGoal: "ai_action_plan_template_goal",
  updateAiGoal: "ai_action_plan_template_goal/",
  deleteAiGoal: "ai_action_plan_template_goal/",

  createAiTask: "ai_action_plan_template_task",
  updateAiTask: "ai_action_plan_template_task/",
  deleteAiTask: "ai_action_plan_template_task/",
  getAiTaskByID: "ai_action_plan_template_task/",

  createAiResource: "ai_action_plan_template_resource",
  updateAiResource: "ai_action_plan_template_resource/",
  deleteAiResource: "ai_action_plan_template_resource/",
  getAiResourceByID: "ai_action_plan_template_resource/",
  // users
  users: {
    GET_USERS_BY_ROLE: "users/get_by_role/",
    GET_COACHEE_FROM_SPONSOR: "/users/get_coachee_from_sponsor",
    GET_USERS_FROM_COACHING_PROGRAM: "users/get_user/",
    GET_USERS_FROM_SPONSOR: "users/get_users_from_sponsor",
    GET_PM_BY_COACHING_PROGRAM: "users/managers_by_user_coaching_program",
    GET_TOTAL_SPONSORING_ENTITY: "/sponsoring_entity/total_items",
  },

  customers: {
    GET_CUSTOMER_ORGANIZATIONS: "customers",
  },
  createNewUserUrl: "users",
  updateUserUrl: "users/",
  getUserById: "users/",

  // groups
  getAllGroups: "/groups",
  GET_GROUPS_BY_SPONSORS: "/groups/by_sponsor",
  createNewGroupUrl: "/groups",
  updateGroup: "/groups",
  getGroupById: "/groups",
  DELETE_GROUP: "groups/",
  GET_TOTAL_GROUPS: "/groups/total_item",

  // customers
  getCustomers: "customers",
  getCustomerOrganizationsForDropdown: "customers",
  getCustomerById: "customers/",
  updateCustomer: "customers/",
  createCustomer: "customers",
  deletePrimaryContact: "customers/customer_contacts/",

  // sponsoring entities
  getSponsoringEntities: "sponsoring_entity",
  createNewSponsoringEntity: "sponsoring_entity",
  getSponsoringEntityByID: "sponsoring_entity/",
  updateSponsoringEntity: "sponsoring_entity/",
  getSponsoringEntitiesForDropdown: "sponsoring_entity?current_page=-1",

  // coaching programs
  coachingPrograms: {
    GET_COACHING_PROGRAMS: "coaching_program",
    GET_COACHING_PROGRAMS_BY_SPONSOR: "coaching_program/sponsor",
    DELETE_COACHING_PROGRAM_BY_ID: "coaching_program",
  },
  createCoachingProgram: "coaching_program",
  getCoachingProgramByID: "coaching_program/",
  updateCoachingProgram: "coaching_program/",

  // keywords substitutions
  getKeywords: "keyword_substitution/list/",
  createKeywords: "keyword_substitution/CreateOrUpdate",
  deleteKeywords: "keyword_substitution/",

  checkEmail: "users/get_by_role/",
  checkUser: "users/get_by_role/",

  // sas token api
  getSASToken: "azure_blob",

  //videoSessionUpdate api
  VideoSessionUpdate: "/customers/video_session_update/",

  //activity report
  getActivityReports: "/reports",

  // admin control
  getAllPermissions: "customers/all_customer_claims_by_role/",
  updatePermissions: "customers/all_customer_claims_by_role/",
  GET_ALL_CUSTOMERS_ORGANIZATION_PERMISSIONS:
    "customers/customers_claims_by_role/",
  getActionTemplateForCoachingPrograms: "/action_plan_template",

  control_feedback: "customers/feedback_update/",

  // sessions
  getSessionsHistory: "sessions/session_history",
  // for coach and coachee users
  getMyHistorySession: "sessions/session_history_me",
  getSessions: "sessions",

  getUpcomingSessions: "sessions/upcoming_sessions",
  // for coach and coachee users
  getMyUpcomingSessions: "sessions/upcoming_sessions_me",
  //for Sponsor user
  GET_UPCOMING_SESSIONS_BY_SPONSOR: "sessions/upcoming_sessions_sponsor",
  GET_SESSIONS_HISTORY_BY_SPONSOR: "sessions/session_history_for_sponsor",

  createUpcomingSession: "sessions",
  getSessionById: "sessions/",
  updateSession: "sessions/",

  rescheduleSession: "sessions/reschedule_session/",
  confirmSession: "sessions/confirm_session/",

  // engagements
  getEngagements: "engagements",

  sessions: {
    GET_UPCOMING_SESSIONS: "sessions/upcoming_sessions",
    GET_UPCOMING_SESSIONS_BY_COACHING_PROGRAM: "sessions/upcoming_sessions_cp",
    GET_SESSIONS_HISTORY: "sessions/session_history",
    GET_SESSIONS_HISTORY_BY_COACHING_PROGRAM:
      "sessions/session_history_by_coaching_program",
    CONDUCT_VIDEO_SESSION: "/sessions/conduct_session/",
    GET_RECORDING: "/sessions/get_recording",
    DOWNLOAD_ICS_FILE: "/sessions/ics_file",
  },
  // engagement console
  engagementConsole: {
    getAllEngagements: "engagements",
    getEngagementsbyme: "engagements/me",
    getAllCoachingPrograms: "coaching_program",
    createEngagements: "engagements",
    deleteEngagmentById: "engagements",
    getEngagementById: "engagements/",
    updateEngagement: "engagements/",
    getEngagementByType: "engagements/by_type",
    GET_ENGAGEMENT_BY_TYPE: "engagements/by_type_user",
    getCoachesByCoachingProgram: "users/coaches_by_coaching_program",
    GET_COACH_BY_COACHING_PROGRAM: "users/coach_by_coaching_program",
    getActionPlanByCoachingProgram: "action_plan_template/by_coaching_program",
    getSponsoringEntityByCoacheeORGroupID: "user_sponsoring_entity",
    VIEW_REPORTS: "coachee_action_plan/coachee_node_progress",
    VIEW_REPORTS_FOR_GROUP: "coachee_action_plan/for_group",
    GET_FEEDBACK_BY_ENGAGEMENT_ID: "feed_back/by_engagement_id",
    GET_COACHEE_ACTION_PLAN_BY_GROUP: "coachee_action_plan/for_group",
    GET_ENGAGEMENTS_BY_SPONSOR: "engagements/for_sponsor",
  },

  // journal enteris
  getAllJournalEnteries: "journal_entries",
  getMyJounalEnteries: "journal_entries/me",
  createJournalEntry: "journal_entries",
  updateJournalEntry: "journal_entries/",
  getJournalEntryById: "journal_entries/",

  // feedback sessions
  createFeedback: "feed_back/",
  updateFeedback: "feed_back/",
  getMyFeedback: "feed_back/me/",
  getAllFeedback: "feed_back",

  // Manage No-Sessions
  manageNoShowSession: "/sessions/no_show/",
  getNoShowCoachees: "/sessions/no_show/",

  // Action Plans
  actionPlanTemplate: {
    getActionPlans: "action_plan_template",
    createActionPlan: "action_plan_template",
    UPDATE_ACTION_PLAN: "action_plan_template",

    createObjective: "action_plan_template_objective",
    updateObjective: "action_plan_template_objective/",
    deleteObjective: "action_plan_template_objective/",

    createGoal: "action_plan_template_goal",
    updateGoal: "action_plan_template_goal/",
    deleteGoal: "action_plan_template_goal/",

    createTask: "action_plan_template_task",
    updateTask: "action_plan_template_task/",
    deleteTask: "action_plan_template_task/",
    getTaskByID: "action_plan_template_task/",

    createResource: "action_plan_template_resource",
    updateResource: "action_plan_template_resource/",
    deleteResource: "action_plan_template_resource/",
    getResourceByID: "action_plan_template_resource/",

    getActionPlanByID: "action_plan_template",
    REORDER_ACTION_PLAN: "action_plan_template_objective/reorder_nodes",
    REORDER_AI_ACTION_PLAN: "ai_action_plan_template_objective/reorder_nodes",
    MERGE_TEMPLATES: "action_plan_template/merge_template",

    CONVERT_ACTION_PLAN_TO_TEMPLATE: "action_plan_template/convert_plan",

    //coachee action plan
    GET_ALL_COACHEE_ACTION_PLANS: "coachee_action_plan",
    GET_COACHEE_ACTION_PLAN_BY_ID: "coachee_action_plan/",
    MERGE_COACHEE_ACTION_PLAN: "coachee_action_plan/merge_template",
    REORDER_COACHEE_ACTION_PLAN: "coachee_action_plan_objective/reorder_nodes",

    UPDATE_COACHEE_ACTION_PLAN: "coachee_action_plan",

    ADD_COACHEE_OBJECTIVE: "coachee_action_plan_objective",
    UPDATE_COACHEE_OBJECTIVE: "coachee_action_plan_objective",
    DELETE_COACHEE_OBJECTIVE: "coachee_action_plan_objective",

    ADD_COACHEE_GOAL: "coachee_action_plan_goal",
    UPDATE_COACHEE_GOAL: "coachee_action_plan_goal",
    DELETE_COACHEE_GOAL: "coachee_action_plan_goal",

    ADD_COACHEE_TASK: "coachee_action_plan_task",
    GET_COACHEE_TASK_BY_ID: "coachee_action_plan_task",
    UPDATE_COACHEE_TASK: "coachee_action_plan_task",
    DELETE_COACHEE_TASK: "coachee_action_plan_task",

    ADD_COACHEE_RESOURCE: "coachee_action_plan_resource",
    GET_COACHEE_RESOURCE_BY_ID: "coachee_action_plan_resource",
    UPDATE_COACHEE_RESOURCE: "coachee_action_plan_resource",
    DELETE_COACHEE_RESOURCE: "coachee_action_plan_resource",

    // Interactive Tool
    ListingTool: {
      CREATE_LISTING_TOOL: "listing_tool",
      GET_LISTING_TOOL: "listing_tool",
      UPDATE_LISTING_TOOL: "listing_tool",

      CREATE_COACHEE_LISTING_TOOL: "coachee_action_plan_listing_tool",
      GET_COACHEE_LISTING_TOOL: "coachee_action_plan_listing_tool",
      UPDATE_COACHEE_LISTING_TOOL: "coachee_action_plan_listing_tool",

      CREATE_COACHEE_RESPONSE: "listing_tool/response",
      GET_COACHEE_RESPONSE: "listing_tool/response",
      UPDATE_COACHEE_RESPONSE: "listing_tool/response",

      GET_LISTING_TOOLS_BY_ACTION_PLAN_ID: "listing_tool/get_list",
      GET_LISTING_TOOLS_BY_COACHEE_ACTION_PLAN_ID:
        "coachee_action_plan_listing_tool/get_list",
    },
    MatrixTool: {
      CREATE_MATRIX_TOOL: "matrix_tool",
      GET_MATRIX_TOOL: "matrix_tool",
      UPDATE_MATRIX_TOOL: "matrix_tool",

      CREATE_COACHEE_MATRIX_TOOL: "coachee_action_plan_matrix_tool",
      GET_COACHEE_MATRIX_TOOL: "coachee_action_plan_matrix_tool",
      UPDATE_COACHEE_MATRIX_TOOL: "coachee_action_plan_matrix_tool",

      CREATE_COACHEE_MATRIX_RESPONSE: "matrix_tool/response",
      GET_COACHEE_MATRIX_RESPONSE: "matrix_tool/response",
      UPDATE_COACHEE_MATRIX_RESPONSE: "matrix_tool/response",
    },
    TriangleTool: {
      CREATE_TRIANGLE_TOOL: "triangle_tool",
      GET_TRIANGLE_TOOL: "triangle_tool",
      UPDATE_TRIANGLE_TOOL: "triangle_tool",

      CREATE_COACHEE_TRIANGLE_TOOL: "coachee_action_plan_triangle_tool",
      GET_COACHEE_TRIANGLE_TOOL: "coachee_action_plan_triangle_tool",
      UPDATE_COACHEE_TRIANGLE_TOOL: "coachee_action_plan_triangle_tool",

      CREATE_COACHEE_TRIANGLE_RESPONSE: "triangle_tool/response",
      GET_COACHEE_TRIANGLE_RESPONSE: "triangle_tool/response",
      UPDATE_COACHEE_TRIANGLE_RESPONSE: "triangle_tool/response",
    },
    SalesMeetingPlanner: {
      CREATE_SALES_MEETING: "sale_meeting_planner",
      GET_SALES_MEETING: "sale_meeting_planner",
      UPDATE_SALES_MEETING: "sale_meeting_planner",
      GET_COACHEE_SALES_MEETNIG: "coachee_action_plan_sale_meeting_planner",
      CREATE_COACHEE_SALES_MEETING: "coachee_action_plan_sale_meeting_planner",
      UPDATE_COACHEE_SALES_MEETING: "coachee_action_plan_sale_meeting_planner",
      coachee: {
        CREATE_SALES_MEETING: "sale_meeting_planner/response",
        GET_SALES_MEETING: "sale_meeting_planner/response",
        UPDATE_SALES_MEETING: "sale_meeting_planner/response",
      },
    },
    PreDefinedFunction: {
      CREATE_PRE_DEFINED_FUNCTION: "pre_define",
      UPDATE_PRE_DEFINED_FUNCTION: "pre_define",
      GET_PRE_DEFINED_FUNCTION: "pre_define",
      CREATE_COACHEE_PRE_DEFINED_FUNCTION: "coachee_action_plan_pre_define",
      GET_COACHEE_PRE_DEFINED_FUNCTION: "coachee_action_plan_pre_define",
      coachee: {
        CREATE_PRE_DEFINED_FUNCTION: "pre_define/response",
        GET_PRE_DEFINED_FUNCTION: "pre_define/response",
        UPDATE_PRE_DEFINED_FUNCTION: "pre_define/response",
      },
    },
    AssessmentTool: {
      CREATE_ASSESSMENT_TOOL: "assesment_tool",
      UPDATE_ASSESSMENT_TOOL: "assesment_tool",
      GET_ASSESSMENT_TOOL: "assesment_tool",
      CREATE_COACHEE_ASSESSMENT_TOOL: "coachee_action_plan_assessment_tool",
      UPDATE_COACHEE_ASSESSMENT_TOOL: "coachee_action_plan_assessment_tool",
      GET_COACHEE_ASSESSMENT_TOOL: "coachee_action_plan_assessment_tool",
      coachee: {
        CREATE_ASSESSMENT_TOOL: "assesment_tool/response",
        UPDATE_ASSESSMENT_TOOL: "assesment_tool/response",
      },
    },
  },
  //Access Audit Logs
  accessAuditLog: {
    getAuditLogs: "audit",
    getActionType: "audit/action_type",
  },
  manageDocuments: {
    createDocument: "document",
    getDocumentbyDocId: "/document/",
    getDocumentUploadedby: "document/me",
    getAssignedDocuments: "/assign_document/document_assigned_to_me",
    getSystemAdminAssignedDoc: "/document/assigned_to_system_admin",
    updateDocument: "/document/",
    assignDocument: "/assign_document",

    //get Coach , Coachee , ProgramManager , CoachingProgram , Groups for Manage Document
    getProgramManager: "/users/managers_by_coach_coaching_program",
    getCoachUser: "/users/coach_by_coach_coaching_program",
    getCoacheeUser: "/users/coachee_by_coach_engagements",
    getCoachingProgram: "/users/coaching_program_by_coach_coaching_program",
  },
  coacheeSessions: {
    getProposedConfirmSession: "/sessions/coachee_sessions",
    getMyUpcomingSession: "/sessions/upcoming_sessions_me",
    getMySessionHistory: "/sessions/session_history_me",
  },
  coacheeActionPlan: {
    getActionPlan: "/coachee_action_plan/by_coachee",
    GET_ACTION_PLAN_BY_ENGAGMENT: "/coachee_action_plan/by_engagment/",
    GET_COACHEE_NODE_PROGRESS: "/coachee_action_plan/coachee_node_progress",
    GET_ENGAGMENT_BY_COACHEE: "/engagements/by_coachee_id",
  },
  coachingProgramByMe: {
    GET_COACHING_PROGRAM_BY_ME: "/coaching_program/me",
  },
  coacheeProgress: {
    getMyProgress: "/engagements/for_coachee",
    getOverAllCoacheeProgress: "/coachee_action_plan/coachee_progress_bar",
  },
  coachee_dashboard: {
    ToDoList: {
      GET_ALL: "coachee_action_plan_task/my_to_do_list",
      MARK_COMPLETE: "coachee_action_plan_task/mark_complete",
      MARK_OBJ_GOAL_COMPLETE: "coachee_action_plan_objective/mark_complete",
    },
  },
  tooltip: {
    TOOLTIP_EDIT: "/tool_tip",
    TOOLTIP_GET: "/tool_tip",
  },
};

export const permissionsType = {
  create: "Create",
  edit: "Edit",
  view: "View",
  export: "Export",
  delete: "Delete",
  viewAll: "View All",
};

export const admindashboard = {
  userType: "Dashboard",
  route: "my-dashboard",
  pageTitle: "My Dashboard",
  permissionName: "Rung Jumper Dashboard",
};
export const userDashboard = {
  userType: "Dashboard",
  route: "dashboard",
  pageTitle: "Dashboard",
};
export const userConsole = {
  route: "user-console",
};

export const adminControl = {
  route: "admin-controls",
  name: "Admin Controls",
};

export const sessions = {
  route: "sessions",
};

export const videoSessionPermission = {
  route: "video-sessions",
  name: "Video Sessions Controls",
  permissionName: "Video Session Setting",
};

export const keywordSubstitution = {
  route: "keyword-substitutions",
  name: "Keyword Substitutions",
  permissionName: "KeyWord Substitution Setting",
};

export const sessionFeedbackPermission = {
  route: "session-feedback",
  name: "Session Feedback Controls",
  permissionName: "Session Feedback Setting",
};

export const rolePermissions = {
  route: "role-permission-customization",
  name: "Manage Role Permissions",
  permissionName: "Roles And Rights Permission Setting",
};
export const systemAdmin = {
  permissionName: "System Admin User Manager",
  permissionNameCustomerAdmin:"Customer Admin User Manager",
  userType: "System Admin",
  route: "manage-system-admin",
  routeTitle: "System Administrators",
  pageTitle: "Manage System Administrators",
  addPageTitle: "Add System Admin",
  addPageRoute: "add-system-admin",
  editPageTitle: "Edit System Admin",
  editPageRoute: "edit-system-admin",
  viewPageTitle: "View System Admin",
  viewPageRoute: "view-system-admin",
};

export const manageGroups = {
  userType: "Manage Groups",
  routeTitle: "Groups",
  route: "manage-groups",
  pageTitle: "Manage Groups",
  addPageTitle: "Add Group",
  addPageRoute: "add-group",
  editPageTitle: "Edit Group",
  editPageRoute: "edit-group",
  viewPageTitle: "View Group",
  viewPageRoute: "view-group",
  permissionName: "Group User Manager",
  COACHEE_PROGRESS_TITLE: "Coachee Progress Report",
  COACHEE_PROGRESS_ROUTE: "coachee-progress",
};

export const customerAdmin = {
  permissionName: "Customer Admin User Manager",
  routeTitle: "Customer Administrators",
  userType: "Customer Admin",
  route: "manage-customer-admin",
  pageTitle: "Manage Customer Administrators",
  addPageTitle: "Add Customer Administrators",
  addPageRoute: "add-customer-admin",
  editPageTitle: "Edit Customer Admin",
  editPageRoute: "edit-customer-admin",
  viewPageTitle: "View Customer Admin",
  viewPageRoute: "view-customer-admin",
};
export const programManager = {
  permissionName: "Program Manager User Manager",
  userType: "Program Manager",
  route: "manage-program-manager",
  pageTitle: "Manage Program Managers",
  addPageTitle: "Add Program Manager",
  addPageRoute: "add-program-manager",
  editPageTitle: "Edit Program Manager",
  editPageRoute: "edit-program-manager",
  viewPageTitle: "View Program Manager",
  viewPageRoute: "view-program-manager",
};

export const coaches = {
  userType: "Coach",
  route: "manage-coaches",
  pageTitle: "Manage Coaches",
  addPageTitle: "Add Coach",
  addPageRoute: "add-coach",
  editPageTitle: "Edit Coach",
  editPageRoute: "edit-coach",
  viewPageTitle: "View Coach",
  viewPageRoute: "view-coach",
};

export const coachees = {
  userType: "Coachee",
  route: "manage-coachees",
  pageTitle: "Manage Coachees",
  addPageTitle: "Add Coachee",
  addPageRoute: "add-coachee",
  editPageTitle: "Edit Coachee",
  editPageRoute: "edit-coachee",
  viewPageTitle: "View Coachee",
  viewPageRoute: "view-coachee",
};

export const customerOrganizations = {
  userType: "Customer Organization",
  route: "manage-customer-organizations",
  pageTitle: "Manage Customer Organizations",
  addPageTitle: "Add New Organization",
  addPageRoute: "add-new-organization",
  editPageTitle: "Edit Customer Organization",
  editPageRoute: "edit-customer-organization",
  viewPageTitle: "View Customer Organization",
  viewPageRoute: "view-customer-organization",
  permissionName: "Customer Organization Manager",
};

export const sponsor = {
  userType: "Sponsor",
  route: "manage-sponsors",
  pageTitle: "Manage Sponsors",
  addPageTitle: "Add Sponsor",
  addPageRoute: "add-sponsor",
  editPageTitle: "Edit Sponsor",
  editPageRoute: "edit-sponsor",
  viewPageTitle: "View Sponsor",
  viewPageRoute: "view-sponsor",
  permissionName: "Sponsor User Manager",
};

export const sponsoringEntity = {
  userType: "Sponsoring Entity",
  route: "manage-sponsoring-entities",
  pageTitle: "Manage Sponsoring Entities",
  addPageTitle: "Add Sponsoring Entity",
  addPageRoute: "add-sponsoring-entity",
  editPageTitle: "Edit Sponsoring Entity",
  editPageRoute: "edit-sponsoring-entity",
  viewPageTitle: "View Sponsoring Entity",
  viewPageRoute: "view-sponsoring-entity",
  permissionName: "Sponsor Entity Manager",
};

export const coachingPrograms = {
  userType: "Coaching Program",
  route: "coaching-programs-console",
  pageTitle: "Coaching Programs",
  addPageTitle: "Create Coaching Program",
  addPageRoute: "add-coaching-program",
  editPageTitle: "Edit Coaching Program",
  editPageRoute: "edit-coaching-program",
  viewPageTitle: "View Coaching Program",
  viewPageRoute: "view-coaching-program",
  permissionName: "Coaching Program Management",
};
export const ActionPlanTemplateConstants = {
  permissionName: "Action Plan Management",
  userType: "Action Plan Template",
  route: "action-plan-template",
  pageTitle: "Action Plan Template",
  addPageTitle: "Create Action Plan Template",
  addPageRoute: "add-action-plan-template",
  aiPageRoute: "ai-assist",
  editPageTitle: "Edit Action Plan Template",
  editPageRoute: "edit-action-plan-template",
  viewPageTitle: "View Action Plan Template",
  viewPageRoute: "view-action-plan-template",
  ADD_HTML_TEXT_TITLE: "Add Html Text",
  ADD_HTML_TEXT_ROUTE: "add-html-text",
  EDIT_EXISTING_ACTION_PLAN_TITLE: "Edit Existing Plan",
  EDIT_EXISTING_ACTION_PLAN_ROUTE: "edit-existing-action-plan",
  EXISTING_ACTION_PLANS: "Existing Action Plans",
  INTERACTIVE_TOOL_BUILDER: "Interactive Tool Builder",
  INTERACTIVE_TOOL_BUILDER_ROUTE: "interactive-tool-builder",
  COACHEE_INTERACTIVE_TOOL_BUILDER: "Interactive Tool Builder",
  COACHEE_INTERACTIVE_TOOL_BUILDER_ROUTE: "coachee-interactive-tool-builder",
};

export const ResourceAttachmentTypes = {
  HTML: "html",
  FILE: "file",
  INTERACTIVE_TOOL_BUILDER: "interactive-tool-builder",
};

export const historySessions = {
  permissionName: "Previous Session Management",
  route: "view-previous-sessions",
  userType: "Previous Sessions",
  pageTitle: "Previous Sessions",
  viewFeedbackPageTitle: "Session Feedbacks",
  viewFeedbackRoute: "session-feedback",
  viewPageTitle: "View Session",
  viewPageRoute: "view-session",
  sessionFeedbackPermissionName: "Session Feedback",
};
export const upcomingSessions = {
  permissionName: "Upcoming Session Management",
  route: "upcoming-sessions",
  userType: "Upcoming Sessions",
  pageTitle: "Upcoming Sessions",
  addPageTitle: "Create Session",
  addPageRoute: "create-session",
  editPageTitle: "Edit Session",
  editPageRoute: "edit-session",
  viewPageTitle: "View Session",
  viewPageRoute: "view-session",
  journalEnteryUserType: "Journal Entery",
  viewJournalEnteriesGridTitle: "View Jounal Entries",
  viewJournalEnteriesGrid: "view-journal-enteries",
  addJournalTitle: "Add Journal Entry",
  addJournalRoute: "add-journal-entery",
  editJournalRoute: "edit-journal-entery",
  editJournalTitle: "Edit Journal Entry",
  viewJournalTitle: "View Journal Entry",
  viewJournalRoute: "view-journal-entery",
  journalEnteryPermissionName: "Journal Entry",
};
export const aiTemplateAdminControls = {
  permissionName: "AI Template Generation Admin Controls",
  route: "ai-note",
  userType: "Upcoming Sessions",
  pageTitle: "Upcoming Sessions",
  addPageTitle: "Create Session",
  addPageRoute: "create-session",
  editPageTitle: "Edit Session",
  editPageRoute: "edit-session",
  viewPageTitle: "View Session",
  viewPageRoute: "view-session",
  journalEnteryUserType: "Journal Entery",
  viewJournalEnteriesGridTitle: "View Jounal Entries",
  viewJournalEnteriesGrid: "view-journal-enteries",
  addJournalTitle: "Add Journal Entry",
  addJournalRoute: "add-journal-entery",
  editJournalRoute: "edit-journal-entery",
  editJournalTitle: "Edit Journal Entry",
  viewJournalTitle: "View Journal Entry",
  viewJournalRoute: "view-journal-entery",
  journalEnteryPermissionName: "Journal Entry",
};
export const calenderSession = {
  route: "session-calender",
  userType: "Session Calender",
  pageTitle: "Session Calender",
  permissionName: "Session Calendar",
};
export const engagementConsole = {
  userType: "Engagement Console",
  route: "engagement-console",
  pageTitle: "Engagements",
  addPageTitle: "Create Engagement",
  addPageRoute: "create-engagement",
  editPageTitle: "Edit Engagement",
  editPageRoute: "edit-engagement",
  viewPageTitle: "View Engagement",
  viewPageRoute: "view-engagement",
  viewSessionListTitle: "View Session List",
  viewSessionListRoute: "view-session-list",
  viewReportTitle: "View Report",
  viewReportRoute: "view-report",
  EDIT_COACHEE_PROGRESS_TITLE: "Edit Coachee Progress",
  EDIT_COACHEE_PROGRESS_ROUTE: "edit-coachee-progress",
};
export const manageDocument = {
  userType: "Manage Documents",
  route: "manage-documents",
  pageTitle: "Manage Documents",
  addPageTitle: "Add New Document",
  addPageRoute: "add-new-document",
  editPageTitle: "Edit Document",
  editPageRoute: "edit-document",
  assignPageTitle: "Assign Document",
  assignPageRoute: "assign-document",
  permissionName: "Documents Management",
};

export const manageProfile = {
  userType: "Manage Profile",
  route: "manage-profile",
  pageTitle: "Manage Profile",
};
export const changePassword = {
  pageType: "Change Password",
  route: "change-password",
  pageTitle: "Change Password",
  parentRoute: "/manage-profile",
};

export const activityReports = {
  permissionName: "Activity Reports",
  userType: "Activity Report",
  pageTitle: "Activity Report",
};
export const accessAuditLog = {
  userType: "Access Audit Log",
  route: "access-audit-log",
  pageTitle: "Access Audit Log",
  permissionName: "Audit Trail",
};
export const user_roles = [
  {
    text: "Customer Admin",
    value: "Customer Admin",
  },
  {
    text: "Sponsor",
    value: "Sponsor",
  },
  {
    text: "Program Manager",
    value: "Program Manager",
  },
  {
    text: "Coach",
    value: "Coach",
  },
  {
    text: "Coachee",
    value: "Coachee",
  },
];
export const action_type = [
  {
    text: "All Audit Type",
    value: "All Audit Type",
  },
  {
    text: "Merge",
    value: "Merge",
  },
  {
    text: "Get All",
    value: "Get All",
  },
  {
    text: "Change Password",
    value: "Change Password",
  },
  {
    text: "Verified",
    value: "Verified",
  },
  {
    text: "Login",
    value: "Login",
  },
  {
    text: "Get",
    value: "Get",
  },
  {
    text: "Refresh",
    value: "Refresh",
  },
  {
    text: "Error",
    value: "Error",
  },
  {
    text: "Exception",
    value: "Exception",
  },
  {
    text: "Email",
    value: "Email",
  },
  {
    text: "Update",
    value: "Update",
  },
  {
    text: "Delete",
    value: "Delete",
  },
  {
    text: "Reset Password",
    value: "Reset Password",
  },
  {
    text: "Get Group By Id",
    value: "Get Group By Id",
  },
  {
    text: "Get By Id",
    value: "Get By Id",
  },
  {
    text: "Add",
    value: "Add",
  },
];
export const all_roles = {
  systemAdmin: "System Admin",
  customerAdmin: "Customer Admin",
  coachee: "Coachee",
  coach: "Coach",
  sponsor: "Sponsor",
  programManager: "Program Manager",
};

export const system_time_zones = [
  // setting America/LA_PAz for AST because both time zones are same, and there is no option fo AST in moment library
  { text: "Atlantic Standard Time", value: "America/La_Paz" },
  { text: "Eastern Standard Time", value: "EST" },
  { text: "Central Standard Time", value: "CST6CDT" },
  { text: "Mountain Standard Time", value: "MST" },
  { text: "Pacific Standard Time", value: "PST8PDT" },
];

export const exportOptions = [
  {
    id: "export-pdf",
    text: "Export PDF",
    icon: "ant-design:file-pdf-outlined",
    clickHandler: exportPDF,
  },
  {
    id: "export-excel",
    text: "Export Excel",
    icon: "ant-design:file-excel-outlined",
    clickHandler: exportCSV,
  },
];

export const genericConfigs = {
  method: "get",
  headers: {
    "X-CSCAPI-KEY": "U0xxcjdKS0ZHcE90RXFZZ1VuYVNnWlM2ZU54cUh6MzVybWZWajFSTg==",
  },
};

export const ActionPlanTypes = {
  ACTION_PLAN: "Action Plan",
  OBJECTIVE: "Objective",
  GOAL: "Goal",
  TASK: "Task",
  RESOURCE: "Resource",
  TEMPLATE_HEADER: "Template Header",
  TEMPLATE: "Template",
};

export const ErrorMessages = {
  NO_DATA_FOUND: "No Data Found",
  SELECT_CUSTOMER_ORGANIZATION: "Select Customer Organization",
  ActionPlanTemplate: {
    GET_EXISTING_PLANS: "Failed to get Existing Action Plans",
    GET_ACTION_PLANS: "Failed to get Action Plan data",
    CONVERT_ACTION_PLAN_TO_TEMPLATE:
      "Failed to convert Action Plan into Template",
    GET_COACHING_PROGRAMS: "Failed to get Coaching Programs",
    GET_CUSTOMER_ORGANIZATIONS: "Failed to get Customer Organizations",
  },
  SESSION_TIMEDOUT: "Session timed out.",
};
export const SuccessMessage = {
  ActionPlanTemplate: {
    CONVERT_ACTION_PLAN_TO_TEMPLATE:
      "Action Plan Successfully Converted into Template",
  },
};

export const DateTypes = {
  FIXED: "fixed",
  RELATIVE: "relative",
};

export const AttachmentTypes = {
  FILE: "file",
  HTML: "html",
};

export const EngagementTypes = {
  INDIVIDUAL: "Individual",
  GROUP: "Group",
};

export const SessionTypes = {
  UPCOMING: "Upcoming Sessions",
  HISTORY: "Previous Sessions",
};

export const FilterTypes = {
  CUSTOMER_ORGANIZATION: "Customer Organization",
  COACHING_PROGRAM: "Coaching Program",
};

export const noOrganizationSelectedOption = [
  {
    text: "Select Customer Organization",
    id: null,
  },
];

export const noDataOption = [
  {
    text: ErrorMessages.NO_DATA_FOUND,
    id: null,
  },
];

export const SelectAllItemsText = {
  ALL_CUSTOMER_ORGANIZATIONS: "All Customer Organizations",
  ALL_COACHING_PROGRAMS: "All Coaching Programs",
};

export const ActiveEngagementsLegendText = {
  AVG_ON_TRACK: "%avg on Track",
  AVG_NOT_ON_TRACK: "%avg not on Track",
  AVG_NOT_STARTED: "not started",
};

export const PageEntries = ["5", "10", "25", "50", "100"];

export const ActiveUsersText = {
  COACH: "Coach",
  COACHEE: "Coachee",
  SPONSOR: "Sponsor",
  PROGRAM_MANAGER: "Program Managers",
  GROUP: "Group",
  SPONSORING_ENTITY: "Sponsoring Entity",
};

export const SessionStatus = {
  CONFIRMED: "Confirmed",
  PROPOSED: "Proposed",
};

export const DocumentTypes = {
  UPLOADED: "Uploaded Documents",
  ASSIGNED: "Assigned Documents",
};

export const columnFlexStyles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  height: "100%",
  width: "100%",
};
export const ChartData = [
  {
    name: "avg_on_time",
    label: ActiveEngagementsLegendText.AVG_ON_TRACK,
    value: 0,
    fill: colors.primary.main,
  },
  {
    name: "avg_not_on_time",
    label: ActiveEngagementsLegendText.AVG_NOT_ON_TRACK,
    value: 0,
    fill: colors.secondary[800],
  },
  {
    name: "not_started",
    label: ActiveEngagementsLegendText.AVG_NOT_STARTED,
    value: 0,
    fill: colors.light.blue,
  },
];

export const MatrixToolShapes = {
  CIRCLE: "circle",
  RECTANGLE: "rectangle",
};

export const TriangleToolDirections = {
  UPWARD: "upward",
  DOWNWARD: "downward",
};

export const ArrowLabels = {
  LOW: "Low",
  HIGH: "High",
};

export const PriorityTypes = {
  NUMERIC_RANGE: "numeric range",
  UNIQUE_VALUE: "unique value",
};

export const OrderingTypes = {
  ASCENDING: "ascending",
  DESCENDING: "descending",
};

export const ParameterOptions = {
  ABSOLUTE: "absolute",
  MAXIMUM: "maximum",
  MINIMUM: "minimum",
};
