import React, { useEffect, useState } from "react";
import PageHeader from "components/custom-components/page-header";
import MDBox from "components/MDBox";
import colors from "assets/theme/base/colors";
import KeywordFeedback from "./KeywordFeedback";
import { Card, IconButton } from "@mui/material";
import MDButton from "components/MDButton";
import TemplateFeedbackQuestions from "./TemplateFeedbackQuestions";
import { FeedbackType } from "utils/enums";
import { ai_assist_feedback_records } from "utils/apiMethods";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Questions = () => {
  const [activeFeedback, setActiveFeedback] = useState(null);
  const [keywordsFeedbackQuestions, setKeywordsFeedbackQuestions] = useState(
    []
  );
  const [templateFeedbackQuestions, setTemplateFeedbackQuestions] = useState(
    []
  );

  const handleButtonClick = (feedbackType) => {
    setActiveFeedback(feedbackType);
  };

  const handleBackButtonClick = () => {
    setActiveFeedback(null);
  };

  const getKeywordFeedbackQuestions = async () => {
    const feedbackType = FeedbackType.Keyword;
    try {
      const response = await ai_assist_feedback_records.getFeedbackQuestions(
        feedbackType
      );
      setKeywordsFeedbackQuestions(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const getTemplateFeedbackQuestions = async () => {
    const feedbackType = FeedbackType.Template;
    try {
      const response = await ai_assist_feedback_records.getFeedbackQuestions(
        feedbackType
      );
      setTemplateFeedbackQuestions(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    getKeywordFeedbackQuestions();
    getTemplateFeedbackQuestions();
  }, []);

  return (
    <MDBox my={3}>
      <PageHeader
        title="Manage AI Assist Feedback Questions"
        canSearch={false}
      />
      {activeFeedback === null ? (
        <Card
          sx={{
            backgroundColor: colors.background.secondary,
            color: "white",
            borderRadius: "16px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "16px",
            height: "300px",
            width: { xs: "30%", sm: "40%", md: "60%", lg: "80%" },
            margin: "0 auto",
            marginTop: "70px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <MDButton
              onClick={() => handleButtonClick("keyword")}
              color="primary"
              style={{ margin: "0 8px", height: "70px", width: "250px" }}
            >
              Manage Keyword Feedback Questions
            </MDButton>
            <MDButton
              onClick={() => handleButtonClick("template")}
              color="primary"
              style={{ margin: "0 8px", height: "70px", width: "250px" }}
            >
              Manage Template Feedback Questions
            </MDButton>
          </div>
        </Card>
      ) : (
        <>
          <MDBox style={{ display: "flex", flexDirection: "row" }}>
            <MDBox mb={4} mt={4}>
              <IconButton
                onClick={handleBackButtonClick}
                sx={{
                  position: "absolute",
                  top: "20px",
                  left: "20px",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: colors.background.secondary,
                  borderRadius: "50%",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  marginTop: "15px",
                  marginLeft: "-15px",
                  marginBottom: "25px",
                }}
              >
                <ArrowBackIcon color="primary" />
              </IconButton>
            </MDBox>
            <MDBox mt={8} mb={4}>
              {activeFeedback === "keyword" && (
                <KeywordFeedback
                  keywordsFeedbackQuestions={keywordsFeedbackQuestions}
                />
              )}
              {activeFeedback === "template" && (
                <TemplateFeedbackQuestions
                  templateFeedbackQuestions={templateFeedbackQuestions}
                />
              )}
            </MDBox>
          </MDBox>
        </>
      )}
    </MDBox>
  );
};

export default Questions;
