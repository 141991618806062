import React, { useEffect, useState } from "react";
import {
  Card,
  IconButton,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Skeleton,
} from "@mui/material";
import { Delete, Edit, Save } from "@mui/icons-material";
import MDBox from "components/MDBox";
import colors from "assets/theme/base/colors";
import PageHeader from "components/custom-components/page-header";
import { keyword_categories } from "utils/apiMethods";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [editCategoryId, setEditCategoryId] = useState(null);
  const [editCategory, setEditCategory] = useState("");
  const [loading, setLoading] = useState(true); // State to manage loading

  // Handle adding a new category
  const handleAddCategory = () => {
    if (newCategory.trim() !== "") {
      addKeywordCategory(newCategory);
      setNewCategory("");
    }
  };

  // Add category to backend and state
  const addKeywordCategory = async (categoryName) => {
    try {
      const payload = { category: categoryName };
      const response = await keyword_categories.saveKeyWordCategories(payload);
      setCategories([...categories, response.data]);
    } catch (error) {
      console.error("Error saving keyword category:", error);
    }
  };

  // Delete category by ID
  const handleDeleteCategory = async (id) => {
    try {
      await keyword_categories.deleteKeywordCategroy(id);
      setCategories(categories.filter((category) => category.keyword_category_id !== id));
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const [validationError, setValidationError] = useState("");

  // Start editing a category
  const handleEditCategory = (id) => {
    const categoryToEdit = categories.find((cat) => cat.keyword_category_id === id);
    setEditCategoryId(id);
    setEditCategory(categoryToEdit.category);
  };

  const handleUpdateCategory = async () => {
    if (editCategory.trim() === "") {
      setValidationError("Category cannot be empty.");
      return;
    }
    setValidationError(""); // Clear the error if the input is valid
    try {
      const updatedCategory = { category: editCategory };
      await keyword_categories.editKeywordcategory(editCategoryId, updatedCategory);
      setCategories(
        categories.map((cat) =>
          cat.keyword_category_id === editCategoryId ? { ...cat, category: editCategory } : cat
        )
      );
      setEditCategoryId(null);
      setEditCategory("");
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  // Fetch all categories
  const getAllCategories = async () => {
    try {
      const response = await keyword_categories.getAllCategories();
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  return (
    <MDBox my={3}>
      <PageHeader title="Manage Keyword Categories For AI Assist" canSearch={false} />
      <Card
        sx={{
          backgroundColor: colors.background.secondary,
          color: "white",
          borderRadius: "16px",
          padding: "16px",
          width: { xs: "90%", md: "80%", lg: "100%" },
          margin: "0 auto",
          marginTop: "70px",
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="categories table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Category Name</TableCell>
                <TableCell sx={{ fontWeight: "bold", textAlign: "right" }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading
                ? Array.from(new Array(5)).map((_, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Skeleton variant="text" width="80%" />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton variant="circular" width={40} height={40} />
                      </TableCell>
                    </TableRow>
                  ))
                : categories.map((category) => (
                    <TableRow key={category.keyword_category_id}>
                      <TableCell component="th" scope="row">
                        {editCategoryId === category.keyword_category_id ? (
                          <TextField
                            value={editCategory}
                            onChange={(e) => setEditCategory(e.target.value)}
                            fullWidth
                            error={Boolean(validationError)}
                            helperText={validationError}
                          />
                        ) : (
                          <span>{category.category}</span>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {editCategoryId === category.keyword_category_id ? (
                          <IconButton onClick={handleUpdateCategory}>
                            <Save color="primary" />
                          </IconButton>
                        ) : (
                          <IconButton onClick={() => handleEditCategory(category.keyword_category_id)}>
                            <Edit color="primary" />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </MDBox>
  );
};

export default Categories;
