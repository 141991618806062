import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  Chip,
  Pagination, // Material UI pagination component
  Skeleton, // Material UI Skeleton component
} from "@mui/material";
import MDBox from "components/MDBox";
import PageHeader from "components/custom-components/page-header";
import FeedbackDetails from "./FeedbackDetails";
import colors from "assets/theme/base/colors";
import { ai_assist_feedback_records } from "utils/apiMethods";
import { Button } from "devextreme-react";

const FeedbackUserList = () => {
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [showList, setShowList] = useState(true);
  const [feedbackRecords, setFeedbackRecords] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [recordsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0); // Track total record count from the API
  const [loading, setLoading] = useState(true); // Loading state

  const handleViewDetails = (feedback) => {
    setSelectedFeedback(feedback);
    setShowList(false);
  };

  const handleBack = () => {
    setSelectedFeedback(null);
    setShowList(true);
  };

  const getAllFeedbackRecords = async (page = 1) => {
    try {
      setLoading(true);
      const response = await ai_assist_feedback_records.getAllFeedbackRecords({
        currentPage: page,
      });
      const apiFeedbackRecords = response.data.feedback_Responses;
      const total = response.data.total_count;

      setTotalCount(total);

      const transformedFeedbackRecords = apiFeedbackRecords.map(
        (item, index) => {
          const feedback = item.feedbacks;
          const response_data =
            feedback?.feedback_question_responses.map((response) => ({
              question: response.question,
              question_type:
                response.question_type === 1 ? "rating" : "boolean",
              question_response: response.answer,
            })) || [];

          return {
            id: index + 1,
            user_name: `${item.user_first_name} ${item.user_last_name}`,
            comment: feedback?.comments || "No comments",
            formTypesFilled: [feedback?.feedback_type],
            template_title: item.title,
            template_id: item.template_id,
            response_date: new Date(item.completed_date).toLocaleDateString(),
            organization_name: item.organization_name,
            user_email: item.user_email,
            user_phone_number: item.user_phone_number,
            response_data,
          };
        }
      );

      setFeedbackRecords(transformedFeedbackRecords);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching feedback records:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllFeedbackRecords(currentPage);
  }, [currentPage]);

  // Calculate records to be shown for the current page
  const currentFeedbacks = feedbackRecords.slice(
    (currentPage - 1) * recordsPerPage,
    currentPage * recordsPerPage
  );

  useEffect(() => {
    console.log("Current Feedbacks:", currentFeedbacks); // Check if data is populated
  }, [currentFeedbacks]);

  const FeedbackRow = ({ feedback }) => {
    return (
      <TableRow>
        <TableCell>{feedback.user_name}</TableCell>
        <TableCell>{feedback.organization_name}</TableCell>
        <TableCell style={{ display: "flex", flexDirection: "column" }}>
          {feedback.formTypesFilled?.includes(1) && (
            <Chip
              style={{ width: "150px", marginBottom: "5px" }}
              label="Keyword Form"
              color="primary"
            />
          )}
          {feedback.formTypesFilled?.includes(2) && (
            <Chip
              style={{ width: "150px", marginBottom: "5px" }}
              label="Template Form"
              color="secondary"
            />
          )}
        </TableCell>
        <TableCell>{feedback.template_title}</TableCell>
        <TableCell>{feedback.response_date}</TableCell>
        <TableCell>
          <Button
            variant="contained"
            onClick={() => handleViewDetails(feedback)}
          >
            View Details
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <MDBox my={3}>
      <PageHeader title="AI Assist Feedback Records" canSearch={false} />
      <Card
        sx={{
          backgroundColor: colors.background.secondary,
          borderRadius: "16px",
          padding: "16px",
          width: { xs: "90%", md: "80%", lg: "100%" },
          margin: "0 auto",
          marginTop: "70px",
        }}
      >
        {showList ? (
          <>
            <TableContainer component={Paper} sx={{ backgroundColor: "white" }}>
              <Table aria-label="feedback user list">
                <TableHead>
                  <TableRow>
                    <TableCell>User</TableCell>
                    <TableCell>Organization</TableCell>
                    <TableCell>Forms Filled</TableCell>
                    <TableCell>Template Title</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading
                    ? // Display skeleton loaders while data is being fetched
                      Array.from({ length: recordsPerPage }).map((_, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Skeleton variant="text" width={100} />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="text" width={150} />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="text" width={150} />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="text" width={150} />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="text" width={100} />
                          </TableCell>
                          <TableCell>
                            <Skeleton
                              variant="rectangular"
                              width={120}
                              height={40}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    : feedbackRecords.map((feedback) => (
                        <FeedbackRow key={feedback.id} feedback={feedback} />
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* Pagination controls */}
            <Pagination
              count={Math.ceil(totalCount / recordsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              sx={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            />
          </>
        ) : (
          <FeedbackDetails
            feedbackData={selectedFeedback}
            onBack={handleBack}
          />
        )}
      </Card>
    </MDBox>
  );
};

export default FeedbackUserList;
