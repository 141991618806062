import React, { useState } from "react";
import {
  Card,
  Typography,
  Tooltip,
  Rating,
  Box,
  Divider,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import useKeywords from "custom-hooks/useKeywords";

const FeedbackDetails = ({ feedbackData, onBack }) => {
  console.log(feedbackData);

  const { ActionPlanTemplateConstants } = useKeywords();
  const parentRoute = `/${ActionPlanTemplateConstants.route}`;
  const viewPage = `/${ActionPlanTemplateConstants.viewPageRoute}`;
  const id = feedbackData.template_id;
  console.log(id);

  const navigate = useNavigate();
  const handleTemplateLink = () => {
    navigate(`/action-plan-template/view-action-plan-template/${id}`);
  };
  if (!feedbackData) {
    return (
      <Box sx={{ textAlign: "center", mt: 4 }}>
        <Typography variant="body1" color="error">
          Feedback not found!
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ px: 2, py: 3, maxWidth: "100%", margin: "auto" }}>
      {/* Back Button */}
      <IconButton onClick={onBack} sx={{ mb: 2 }}>
        <ArrowBackIcon color="primary" />
        <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
          Back to List
        </Typography>
      </IconButton>

      {/* Info and Responses in a Row */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: 3,
          mb: 3,
        }}
      >
        {/* User Information Section */}
        <Card sx={{ p: 2, flex: 1 }}>
          <Typography variant="h6" gutterBottom>
            Feedback Details
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Typography variant="body2" sx={{ mb: 1 }}>
            <strong>Name:</strong> {feedbackData.user_name}
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            <strong>Organization:</strong> {feedbackData.organization_name}
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            <strong>Email:</strong> {feedbackData.user_email}
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            <strong>Phone:</strong> {feedbackData.user_phone_number}
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            <strong>Template:</strong>
            <Tooltip title={feedbackData.template_title}>
              <span>
                {feedbackData.template_title.length > 30
                  ? `${feedbackData.template_title.substring(0, 30)}...`
                  : feedbackData.template_title}
              </span>
            </Tooltip>
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            <strong>Response Date:</strong>{" "}
            {feedbackData.response_date || "N/A"}
          </Typography>
          <Typography variant="body2">
            <strong>Template Link:</strong>{" "}
            <button
              onClick={handleTemplateLink}
              style={{
                background: "none",
                border: "none",
                fontSize: "15px",
                color: "blue",
                cursor: "pointer",
              }}
            >
              {feedbackData.template_title || "N/A"}
            </button>
          </Typography>
        </Card>

        {/* Responses Section */}
        <Card sx={{ p: 2, flex: 1 }}>
          <Typography variant="h6" gutterBottom>
            Responses
          </Typography>
          <Divider sx={{ my: 1 }} />
          {feedbackData.response_data &&
          feedbackData.response_data.length > 0 ? (
            feedbackData.response_data.map((response, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <Typography variant="body2">
                  <strong>Question:</strong> {response.question}
                </Typography>
                {response.question_type === "rating" ? (
                  <Rating value={response.question_response} readOnly />
                ) : (
                  <Typography variant="body2">
                    <strong>Response:</strong>{" "}
                    {response.question_response === 1 ? "Yes" : "No"}
                  </Typography>
                )}
              </Box>
            ))
          ) : (
            <Typography variant="body2" color="text.secondary">
              No responses available.
            </Typography>
          )}
        </Card>
      </Box>

      {/* Comments and Suggestions Section Below Info and Responses */}
      <Card sx={{ p: 2, width: "100%" }}>
        <Typography variant="h6" gutterBottom>
          Comments & Suggestions
        </Typography>
        <Divider sx={{ my: 1 }} />
        {feedbackData.comment ? (
          <Typography variant="body2">{feedbackData.comment}</Typography>
        ) : (
          <Typography variant="body2" color="text.secondary">
            No additional comments provided.
          </Typography>
        )}
      </Card>
    </Box>
  );
};

export default FeedbackDetails;
